@import "../../assets/styles/colors";

.customButton {
  min-height: 46px;
  min-width: 250px;

  .buttonIcon {
    height: 30px;
    margin-left: 10px;
  }

  span {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: capitalize;
  }

  .loader {
    color: $white;
    height: 20px;
    width: 20px;
  }

  &.submitButton {
    background: $darkPrimary;
    color: $white;
    padding: 0 20px;
    border-radius: 30px;

    &:hover {
      background: $primary;
    }
  }

  &.iconButton {
    background: $darkPrimary;
    border-radius: 100%;
    min-width: 45px;
    max-width: 45px;
    min-height: 45px;

    &.small {
      min-width: 40px;
      max-width: 40px;
      min-height: 40px;

      .loader {
        height: 16px;
        width: 16px;
      }
    }

    &.verysmall {
      min-width: 28px;
      max-width: 28px;
      min-height: 28px;
      padding: 0;

      .loader {
        height: 16px;
        width: 16px;
      }
    }

    &.mx-2 {
      margin: 0 10px;
    }

    .icon {
      color: $white;
      width: 24px;
      height: 24px;
    }

    span {
      width: fit-content;
    }

    &:hover {
      background: $primary;
    }
  }

  &.disabledButton {
    background: $lightGrey;
    color: $black;
    border-radius: 30px;

    &:hover {
      background: $lightGrey;
    }
  }

  &[disabled] {
    opacity: 0.6;
  }

  &.textDefault {
    background: transparent;
    min-width: 120px;
  }

  &.textYellow {
    background: transparent;
    min-width: 120px;
    color: $darkPrimary;

    .icon {
      font-size: 20px;
    }
  }

  &.full {
    width: 100%;
  }

  &.deleteButton {
    color: $white;
    background: $red;
    min-width: 0;
    border-radius: 30px;

    .loader {
      color: $white;
    }
  }

  &.successButton {
    color: $white;
    background: $green;
    min-width: 0;
    border-radius: 30px;

    .loader {
      color: $white;
    }
  }

  &.primaryButton {
    color: $white;
    background: $darkBlue;
    min-width: 0;
    border-radius: 30px;

    .loader {
      color: $white;
    }
  }

  &.cancelButton {
    color: $darkPrimary;
    border: 2px solid $darkPrimary;
    border-radius: 30px;

    .loader {
      color: $darkPrimary;
    }
  }

  &.mb1 {
    margin-bottom: 10px;
  }
  &.mb2 {
    margin-bottom: 20px;
  }

  &.success {
    color: $white;
    background: $green;
    &:hover {
      background: $darkGreen;
    }
  }

  &.danger {
    color: $white;
    background: $red;
    &:hover {
      background: $darkRed;
    }
  }

  &.cancel {
    color: $white;
    background: $greyBackground;
    &:hover {
      background: $darkestGrey;
    }
  }
}
