@import "../../assets/styles/colors";

.iconButton {
  margin-right: 15px;
  border: 1px $primary solid;
  background-color: $primary;
  color: #fff;
  border-radius: 100% !important;
  transition: 0.3s all;
  .icon {
    width: 15px;
    height: 15px;
  }
}

input {
  margin-left: 10px;

  &.form-control:focus {
    border-color: $darkPrimary;
  }
}
