@import "../../assets/styles/colors";

.display-image-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  button {
    position: absolute;
    top: 0;
    right: 0;
    background: $darkText;
    color: #fff;
    margin-top: 15px;
    margin-right: 20px;
    width: 20px;
    height: 20px;
    padding: 0;
    border-radius: 2px;

    &:hover {
      background: $primary;
    }
  }
}

.uploaded-image {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 150px;
  height: 150px;
  padding: 4px;
  box-sizing: border-box;
  object-fit: contain;
  object-position: center;
}
