@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500&display=swap");
@import "./assets/styles/colors";
body {
  margin: 0;
  font-family: "Poppins", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fafafa !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.primaryColorLink {
  color: $primary;
}

.outlinedInput {
  width: 100%;
  margin: 0;
  background: $bgColor;
  height: 40px;

  fieldset {
    border-color: transparent;
  }

  label {
    transform: translate(14px, 12px) scale(1);
    font-size: 14px;
  }

  input {
    padding: 0 1rem;
  }
  div {
    height: 40px;
    &:before,
    &:after {
      display: none !important;
    }
  }
  svg {
    color: $lightGrey;
  }
  p {
    display: none;
  }
}

/* width */
::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}
