@import "../../../assets/styles/colors";

.heading {
  background: $primary;
  width: fit-content;
  padding: 5px 10px;
  color: white !important;
  border-radius: 5px;
  margin-bottom: 30px;
  text-transform: uppercase;
}
