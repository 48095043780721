@import "../../../assets/styles/colors";

.add-customer-form {
  .MuiGrid-container {
    margin-bottom: 50px;
  }

  div {
    h4 {
      background: $primary;
      width: fit-content;
      padding: 5px 10px;
      color: white;
      border-radius: 5px;
      margin-bottom: 30px;
      text-transform: uppercase;
    }
  }
}
