.dashboard {
  width: 100%;
  height: 100vh;
  overflow: hidden;

  .sideBox {
    max-width: 300px;
    height: 100%;
  }

  .contentBox {
    width: 100%;
    height: 100%;
    overflow-x: scroll;
  }
}
