@import "../../../assets/styles/colors";

.roleUnselected, .roleSelected {
  cursor: pointer;
  color: #fff;
  padding: 5px 20px;
  border: 2px $primary solid;
  border-radius: 4px;
  font-weight: 500;
  margin: 10px 5px 10px 0px;
  transition: 0.3s all;
  width: 150px;  
}

.roleSelected {
  background: $primary;
}

.roleUnselected {
  color: $primary;

  &:hover {
    background-color: $primary;
    color: #fff;
    transition: 0.3s all;
  }
}

