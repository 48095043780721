@import "../../../assets/styles/colors";

.label-heading {
  background: $primary;
  width: fit-content;
  padding: 5px 10px;
  color: #fff !important;
  border-radius: 5px;
  margin-bottom: 30px;
  text-transform: uppercase;
}

td img {
  width: 100px;
  height: 50px;
  object-fit: contain;
  object-position: center;
}
