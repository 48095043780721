@import "../../../assets/styles/colors";

.add-product-form {

  .MuiGrid-container {
    margin-bottom: 50px;
  }

  div {
    h4 {
      background: $primary;
      width: fit-content;
      padding: 5px 10px;
      color: white;
      border-radius: 5px;
      margin-bottom: 30px;
      text-transform: uppercase;
    }
  }
}


.variantAccordion { 
  border: 1px solid #c4c4c4;
  box-shadow: none !important;
}