@import "../../assets/styles/colors";
.usersList {
  width: 100%;
  .button {
    width: 5%;
    border-radius: 10px;
    border: 1px solid $primary;
    background: transparent;
    text-align: center;
    padding: 8px 10px;
    margin-top: 10px;
    cursor: pointer;
    font-weight: 600;
    box-shadow: 0px 10px 10px $boxShadowColor;
    &.selected {
      background: $primary;
      color: $white;
    }
  }
  .tableContainer {
    box-shadow: none;
    background: transparent;
    min-height: 300px;
  }
  .usersListTable {
    border-collapse: separate;
    border-spacing: 0 10px;
    thead {
      background: $primary;

      th {
        font-size: 16px;
        font-weight: 500;
        color: $white;
        padding: 15px;
        border-color: transparent;

        .sortLabel {
          color: #fff;

          svg {
            color: #fff;
          }
        }
      }
    }
    tbody {
      tr {
        background: $white;
        box-shadow: 0px 2px 2px $boxShadowColor;
        transition: 0.3s all;
      }
      tr.activeRow {
        background-color: #2367e733;
        box-shadow: 10px 10px 10px $boxShadowColor;
      }
      tr:hover,
      tr.activeRow:hover {
        box-shadow: 10px 10px 10px $boxShadowColor;
      }
      td {
        color: $darkestGrey;
        padding: 15px;
        font-weight: 400;
        font-size: 14px;
        .tag {
          margin: 0 auto;
        }
      }
    }
    .status {
      cursor: pointer;
      font-weight: 600;
    }
    .checkBox {
      padding: 0;
      margin: 0;
    }
    .viewIcon {
      cursor: pointer;
      color: $white;
      background: $primary;
      width: 36px;
      height: 36px;
      padding: 8px;
      border-radius: 100%;
    }
    .fontAwesomeIcon {
      width: 30px;
      height: 30px;
      color: #b54040;
      transition: 0.3s all;
    }
    .fontAwesomeIcon:hover {
      width: 30px;
      height: 30px;
      color: #c95454;
      transition: 0.3s all;
    }
    .radioField {
      width: 32%;
      border-radius: 30px;
      border: 1px solid $primary;
      background: transparent;
      text-align: center;
      padding: 8px 10px;
      margin-top: 10px;
      cursor: pointer;
      font-weight: 600;
      box-shadow: 0px 10px 10px $boxShadowColor;
      &.selected {
        background: $primary;
        color: $white;
      }
    }
  }
}

.pagination {
  padding-left: 0;
  margin-top: auto;

  p {
    margin: 0;
  }

  .MuiTablePagination-toolbar {
    padding-left: 0;
  }
}
